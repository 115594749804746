<script>
import { defineComponent } from 'vue';
import Home from '../Home.vue';

export default defineComponent({
  components: {
    Home,
  },
});
</script>

<template>
  <div>
    <div class="header">
      Steamcord Demo
    </div>

    <home overrideSlug="demo" />
  </div>
</template>

<style lang="scss">
@import 'https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@700&display=block';
@import '@/assets/stylesheets/colors.scss';

.header {
  color: $primary;
  font-family: 'JetBrains Mono', monospace;
  font-size: 1.125rem;
  font-weight: 700;
}
</style>
